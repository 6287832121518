
import { defineComponent, onMounted, watch, defineProps, ref } from 'vue'
import * as echarts from 'echarts'

export default defineComponent({
  props: {
    pieData: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    onMounted(() => {
      // console.log(props, 'pppppppppppROPS')
      pieDataList.value = []
      props.pieData.forEach((res:any) => {
        pieDataList.value.push({ name: res.state, value: res.numTotal })
      })
      getpieData()
    })
    const pieDataList = ref<any>()
    watch(
      () => props.pieData,
      (res:any) => {
        pieDataList.value = []
        res.forEach((res:any) => {
          pieDataList.value.push({ name: res.state, value: res.numTotal })
        })
        getpieData()
      }
    )

    const getpieData = () => {
      type EChartsOption = echarts.EChartsOption;
      var chartDom = document.getElementById('rightEcharts')!
      var myChart = echarts.init(chartDom)
      var option: EChartsOption

      option = {
        color: ['#3BA272', '#59C4E6', '#191970', '#FC8452', '#B6A2DE', '#9A60B4', '#3FB1E3', '#6BE6C1', '#626C91', '#A0A7E6'],
        legend: {
          top: 'top'
        },
        tooltip: {
          trigger: 'item'
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: false, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: [60, 140],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 6
              //  color: '#FAC858'
            },
            data: pieDataList.value
            // data: [
            //   { value: 40, name: '邮寄中' },
            //   { value: 38, name: '鉴定中' },
            //   { value: 32, name: '付款中' },
            //   { value: 30, name: '未成交' }
            //   //  { value: 28, name: 'rose 5' },
            //   //  { value: 26, name: 'rose 6' },
            //   //  { value: 22, name: 'rose 7' },
            //   //  { value: 18, name: 'rose 8' }
            // ]
          }
        ]
      }

      option && myChart.setOption(option, true)
    }
    return {
      getpieData,
      pieDataList

      // chartDom,
      // myChart,
      // option
    }
  }
})
